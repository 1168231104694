
// swiper
import 'swiper/swiper.min.css';
import '@ionic/vue/css/ionic-swiper.css';
import 'swiper/modules/navigation/navigation.min.css';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { FreeMode, Navigation, Pagination, } from 'swiper';

// icons
import { image, eye, videocamOutline } from 'ionicons/icons';

// components
import { IonicSlides, IonList, IonListHeader, IonImg, IonText, IonIcon, IonButton, IonBadge,
        IonRow, IonCol, modalController, } from '@ionic/vue';
import DashboardPane from '@/components/dashboards/DashboardPane.vue';

// composables
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { utilsDevice } from '@/composables/utilsDevice';
import { computed, onMounted, watch } from 'vue';

export default {
  props: [
    "defaultCaption",
    "listHeader",
    "imageLinks",
    "photos",
    "minSlidesPerView",
    "maxSlidesPerView",
    "size",
    "hideEyeIcon",
    "qualificationImages",
    "floorPlanLocations",
    "deviceAlerts",
    "camDevices", "edgeDevices",
    "useSlotAsSwiperSlides",
    "useGridView",
  ],
  emits: ['clickedOutputMapImg'],
  components: { Swiper, SwiperSlide, IonList, IonListHeader, IonImg, IonText, IonIcon, IonButton, IonBadge,
                IonRow, IonCol,
                DashboardPane, },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { openImageModal, addResizeUrlParams, getProxyImgLink, getRelativeDate, tStr, } = utils();
    const { refreshCamStreaming } = utilsDevice();
    const minSlidesPerView = props.minSlidesPerView || 2.2;
    const ops = {
      slidesPerView: Math.max(minSlidesPerView, 2.2),
      breakpoints: {
        768: { slidesPerView: Math.max(minSlidesPerView, 3), },
        1024: { slidesPerView: Math.max(minSlidesPerView, 4), }
      },
    };
    const propsCamDevices = computed(() => props.camDevices);

    const initCamDevices = () => {
      const { camDevices, edgeDevices, } = props;
      if (camDevices) {
        for (const device of camDevices) {
          refreshCamStreaming(device, edgeDevices, `#video-stream-${device.id}`);
        }
      }
    }

    onMounted(() => {
      initCamDevices();
    });

    watch(propsCamDevices, () => {
      initCamDevices();
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      image, eye, videocamOutline,

      // variables
      ops,

      // methods
      t, tStr, openImageModal, addResizeUrlParams, getProxyImgLink, getRelativeDate,

      // swiper modules
      modules: [IonicSlides, Navigation, Pagination, FreeMode],

      // work locations
      onClickOutputMapPhoto: (wl) => {
        emit('clickedOutputMapImg', wl.id);
      }
    }
  }
}
